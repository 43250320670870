import * as THREE from "three"; //引入Threejs
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
var scene;
export default {
  props: ["modelUrl"],
  data() {
    return {
      width: '300',
      height: '300',
      scene: null,
      camera: null,
      renderer: null,
      clock: null
    };
  },
  mounted() {
    this.init();
    this.loadModel();
  },
  methods: {
    init() {
      let container = document.getElementById("container"); //显示3D模型的容器

      this.width = container.clientWidth;
      this.height = container.clientHeight;
      scene = new THREE.Scene();
      const fov = 45;
      const aspect = this.width / this.height;
      const near = 0.1;
      const far = 1000;
      this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      this.camera.position.set(0, 0, 26);

      /* 渲染区域 */
      this.renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true
      });
      this.renderer.setSize(container.clientWidth, container.clientWidth);
      this.renderer.setClearAlpha(0.0);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.shadowMap.enabled = true;
      this.renderer.hadowMapEnabled = true;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.textureEncoding = THREE.sRGBEncoding;

      // 添加灯光 【重要】
      // function addLights() {
      //     // scene.add( new THREE.AmbientLight( 0x443333 ) );
      //     // const dirLight1 = new THREE.DirectionalLight( 0xffddcc, 1 );
      //     // dirLight1.position.set( 1, 0.75, 0.5 );
      //     // scene.add( dirLight1 );
      //     // const dirLight2 = new THREE.DirectionalLight( 0xccccff, 1 );
      //     // dirLight2.position.set( - 1, 0.75, - 0.5 );
      //     // scene.add( dirLight2 );
      //     scene.add(new THREE.AmbientLight(0xffffff, 0.1));
      //     // var light = new THREE.DirectionalLight(0x443333, 0.8 * Math.PI);
      //     // light.position.set(0, 50, 0);
      //     // scene.add(light);
      // }
      // addLights();

      // 本身发暗的模型就用sunset  
      // 发亮急用court
      // 不需要环境的使用null
      // let str = 'https://p6.moimg.net/md/mdtest/studio_small_02_1k.hdr'
      let str = "https://p6.moimg.net/md/nft/detail/venice_sunset_1k.hdr";
      // let str = 'https://p6.moimg.net/md/mdtest/default_env_ibl.ktx'
      // let str = 'https://p6.moimg.net/md/mdtest/light2.hdr'
      // let str = 'https://p6.moimg.net/md/mdtest/light.hdr'
      // let str = 'https://p6.moimg.net/md/mdtest/default_env_skyboox.hdr'
      // // let str = "/img/venice_sunset_1k.hdr";
      // //   str = "img/footprint_court_2k.hdr";

      var pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();
      try {
        // 单张环境贴图加载器实例化
        new RGBELoader().load(str, function (texture) {
          var envMap = pmremGenerator.fromCubemap(texture).texture;
          scene.environment = envMap;
          texture.dispose();
          pmremGenerator.dispose();
        });
      } catch (e) {
        console.log(e);
      }
      container.appendChild(this.renderer.domElement);

      // const controls = new OrbitControls(this.camera, this.renderer.domElement);
      // controls.update();

      this.clock = new THREE.Clock();
    },
    loadModel() {
      let self = this; //这一点很重要。。
      let loader = new GLTFLoader();
      var dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/fepro/draco/gltf/');
      dracoLoader.setDecoderConfig({
        type: 'js'
      });
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);
      loader.load(self.modelUrl, function (gltf) {
        const loadscene = gltf.scene;
        let group = new THREE.Group();
        group.add(loadscene);

        // loadscene.traverse( function ( child ) {
        // if ( child.isMesh ) {
        // 模型自发光
        // child.material.emissive =  child.material.color;
        // child.material.emissiveMap = child.material.map ;
        // }})

        let bbox = new THREE.Box3().setFromObject(group);
        let mdlen = bbox.max.x - bbox.min.x; //边界的最小坐标值 边界的最大坐标值
        let mdhei = bbox.max.y - bbox.min.y;
        let mdwid = Math.abs(bbox.max.z) - Math.abs(bbox.min.z);
        group.position.set(0, 0, 10);
        let dist = Math.abs(self.camera.position.z - group.position.z - mdwid / 2);
        //console.log('dist值为:' + dist );
        let vFov = self.camera.fov * Math.PI / 180;
        //console.log('vFov值为:' + vFov );
        let vheight = 2 * Math.tan(vFov * 0.5) * dist;
        //console.log('vheight值为:' + vheight );
        let fraction = mdhei / vheight;
        // console.log('fraction值为:' + fraction );
        let finalHeight = self.height * fraction;
        //console.log('finalHeight值为:' + finalHeight);
        let finalWidth = finalHeight * mdlen / mdhei;
        //console.log('finalWidth值为:' + finalWidth );

        let value1 = self.width / finalWidth;
        let value2 = self.height / finalHeight;
        if (value1 >= value2) {
          group.scale.set(value2, value2, value2);
        } else {
          group.scale.set(value1, value1, value1);
        }
        let bbox2 = new THREE.Box3().setFromObject(group);
        group.position.set(-(bbox2.max.x + bbox2.min.x) / 2, -(bbox2.max.y + bbox2.min.y) / 2, -(Math.abs(bbox2.max.z) + Math.abs(bbox2.min.z)) / 2 + 10);
        scene.add(group);

        // let boxhelper = new THREE.BoxHelper(group,0xbe1915); //外面红色框
        // scene.add(boxhelper);

        const mixer = new THREE.AnimationMixer(gltf.scene);
        for (var i = 0; i < gltf.animations.length; i++) {
          mixer.clipAction(gltf.animations[i]).play();
        }
        let T0 = new Date();
        const render = () => {
          const timer = self.clock.getDelta();
          if (mixer) {
            mixer.update(timer);
          }
          let T1 = new Date();
          let t = T1 - T0;
          T0 = T1;
          self.renderer.render(scene, self.camera);
          scene.rotateY(0.00032 * t); //每次绕y轴旋转0.01弧度
          window.requestAnimationFrame(render);
        };
        window.requestAnimationFrame(render);
      });
    }
  }
};