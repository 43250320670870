import Clipboard from 'clipboard';
import { mdToast } from '../../../utils/tools';
export default {
  props: {
    detail: {
      type: Object
    }
  },
  methods: {
    copyActiveCode(e, text) {
      const clipboard = new Clipboard(e.target, {
        text: () => text
      });
      clipboard.on('success', () => {
        mdToast({
          type: 'success',
          message: '复制成功'
        });
        // 释放内存
        clipboard.off('error');
        clipboard.off('success');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        // 不支持复制
        mdToast({
          type: 'waning',
          message: '该浏览器不支持自动复制'
        });
        // 释放内存
        clipboard.off('error');
        clipboard.off('success');
        clipboard.destroy();
      });
      clipboard.onClick(e);
    }
  }
};